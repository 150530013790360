<template>
  <aviso-bloqueio v-if="empresa && !empresa.ativo"/>
  <v-layout column class="mt-4" v-else>
  <v-data-table
    :headers="headers"
    :items="desserts"
    :loading="loading"
    sort-by="inicio"
    class="elevation-1 mt-4"
    hide-default-footer
    no-data-text="Você ainda não possui Cartões cadastrados"
  >
    <template v-slot:item.verificado="{ item }">
      <v-tooltip v-if="item.verificado === 3" top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="teal">mdi-credit-card-check</v-icon>
        </template>
        <span>Cartão verificado </span>
      </v-tooltip>
      <v-tooltip v-else-if="item.verificado <= 2" top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="gray">mdi-credit-card-settings-outline </v-icon>
        </template>
        <span>Cartão não verificado</span>
      </v-tooltip>
      <v-tooltip v-else top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="gray">mdi-card-bulleted-off</v-icon>
        </template>
        <span>Cartão Cancelado</span>
      </v-tooltip>
    </template>
    <template v-slot:item.isActive="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn @click="selecionarCartao(item)" v-on="on" icon :color="isActiveCard(item) ? 'success': 'error'">
              <v-icon>{{ isActiveCard(item) ? 'check_circle_outline' : 'highlight_off'}}</v-icon>
            </v-btn>
          </template>
          <span>{{isActiveCard(item) ? 'Desativar Cartão' : 'Ativar Cartão'}}</span>
        </v-tooltip>
    </template>
    <template v-slot:item.credito>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" color="blue">mdi-credit-card</v-icon>
        </template>
        <span>Crédito
        </span>
      </v-tooltip>
    </template>
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title style="font-size: 1.5em">Meus Cartões</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <template v-if="pessoa && !pessoa.verificado">
          <v-spacer></v-spacer>
          <span class="error--text">Para poder cadastrar um cartão você precisa realizar a verificação de autenticidade em Editar Perfil.</span>
        </template>
        <v-spacer></v-spacer>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              class="mt-1 pa-0"
              fab
              color="primary"
              @click="dialogCard = true"
              :disabled="pessoa && !pessoa.verificado"
              small
              style="height: 2.5em; width: 2.5em;"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Adicionar Cartão</span>
        </v-tooltip>

        <v-dialog
          v-model="dialogCard"
          max-width="500"
          persistent
          v-if="dialogCard"
        >
          <v-card style="border-radius: 1em;" v-if="dialogCard">
            <v-stepper v-model="stepCard">
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card-title>
                    <span class="headline">Qual o endereço de cobrança?</span>
                  </v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item @click="selecionarEndereco(pessoa.endereco)" @mouseenter="hideCheckA = true" @mouseleave="hideCheckA = false" v-if="pessoa && pessoa.endereco">
                        <v-list-item-icon>
                          <v-icon color="info">home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="`${pessoa.endereco.logradouro}, Nº${pessoa.endereco.numero}.`"
                            class="font-weight-light"
                          ></v-list-item-title>
                          <v-list-item-subtitle v-text="`${pessoa.endereco.bairro}, ${pessoa.endereco.cidade} - ${pessoa.endereco.estado}`"></v-list-item-subtitle>
                          <v-list-item-subtitle v-text="`${pessoa.endereco.cep}`"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon v-if="hideCheckA" color="info">
                            check
                          </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item @click="selecionarEndereco(empresa.endereco)" @mouseenter="hideCheckB = true" @mouseleave="hideCheckB = false" v-if="!mesmoEndereco">
                        <v-list-item-icon>
                          <v-icon color="info">business</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="`${empresa.endereco.logradouro}, Nº${empresa.endereco.numero}.`"
                            class="font-weight-light"
                          ></v-list-item-title>
                          <v-list-item-subtitle v-text="`${empresa.endereco.bairro}, ${empresa.endereco.cidade} - ${empresa.endereco.estado}`"></v-list-item-subtitle>
                          <v-list-item-subtitle v-text="`${empresa.endereco.cep}`"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon v-if="hideCheckB" color="info">
                            check
                          </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item @click="stepCard = 2">
                        <v-list-item-icon>
                          <v-icon>add</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          Adicionar Endereço
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions class="justify-space-around">
                    <v-btn text block @click="close">
                      Cancelar
                    </v-btn>
                  </v-card-actions>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-card-title>
                    <span class="headline">Adicionar Novo Endereço</span>
                  </v-card-title>
                  <v-card-text>
                    <v-layout wrap justify-space-between>
                      <v-flex xs12>
                        <v-text-field @keyup="searchCep" v-model="endereco_cartao.cep" label="CEP"/>
                      </v-flex>
                      <v-flex xs9>
                        <v-text-field v-model="endereco_cartao.logradouro" label="Logradouro"></v-text-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-text-field v-model="endereco_cartao.numero" label="Número"></v-text-field>
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field v-model="endereco_cartao.bairro" label="Bairro"></v-text-field>
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field v-model="endereco_cartao.cidade" label="Cidade"></v-text-field>
                      </v-flex>
                      <v-flex xs2>
                        <v-text-field  v-model="endereco_cartao.estado" label="Estado"></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                  <v-card-actions class="justify-space-around pa-4">
                    <v-btn text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="stepCard = 3"
                    >
                      Avançar
                    </v-btn>
                  </v-card-actions>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-card-title class="justify-center">
                    <h4>CADASTRAR NOVO CARTÃO DE CRÉDITO</h4>
                  </v-card-title>
                  <v-alert
                    text
                    class="pa-2"
                    type="error"
                  >
                    Um pequeno valor será cobrado no seu
                    cartão após o cadastro, conclua o processo
                    de verificação para o valor ser estornado.
                  </v-alert>
                  <v-card-text>
                    <v-form
                        ref="formCreditCard"
                        v-model="valid"
                        lazy-validation
                    >
                      <v-layout>
                        <v-row>
                          <v-col cols="15" md="12">
                            <v-text-field
                              v-model="cartao.numero_cartao"
                              label="Numero do Cartão"
                              v-mask="'#### #### #### ####'"
                              :rules="requiredRules"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="15" md="8">
                            <v-text-field
                              v-model="cartao.descricao"
                              label="Descrição do cartão (Apelido)"
                              :rules="requiredRules"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="5" md="4">
                            <v-text-field
                              v-model="cartao.validade_cartao"
                              label="Mês/Ano"
                              v-mask="'##/##'"
                              :rules="requiredRules"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="15" md="8">
                            <v-text-field
                              v-model="cartao.titular"
                              label="Nome do titular escrito no cartão"
                              :rules="requiredRules"
                              required
                            ></v-text-field>
                          </v-col>

                          <v-spacer></v-spacer>
                          <v-col cols="5" md="4">
                            <v-text-field
                              v-model="cartao.cvv_cartao"
                              label="CVV"
                              v-mask="'###'"
                              type="password"
                              :rules="requiredRules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-layout justify-space-around>
                            <v-radio-group
                              v-model="tipo_pessoa"
                              row
                            >
                              <v-radio label="Pessoa Física" value="1"></v-radio>
                              <v-radio label="Pessoa Jurídica" value="2"></v-radio>
                            </v-radio-group>
                          </v-layout>
                        </v-row>
                      </v-layout>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="justify-space-around pa-4">
                    <v-btn text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="hashcartao"
                      :loading="loading"
                    >
                      Cadastrar
                    </v-btn>
                  </v-card-actions>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-dialog>

        <!-- dialog validar cartão -->
        <v-dialog
          v-model="dialog"
          max-width="500px"
          v-if="dialog"
        >
          <v-card v-if="dialog" style="border-radius: 1em;">
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <template>
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step
                  :complete="e1 > 1"
                  step="1"
                >
                  Validar
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 2"
                  step="2"
                >
                  Confirmar
                </v-stepper-step>

                <v-divider></v-divider>

              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card>
                    <v-card-text>
                      <div>
                        <v-alert
                          border="top"
                          colored-border
                          type="info"
                          elevation="2"
                          color="red"
                          v-if="pessoa && !pessoa.verificado"
                        >
                          ATENÇÃO: Para concluir o processo de verificação, é necessário já ter
                          realizado a verificação de autenticidade em <a @click="$router.push('/editarperfil')">Editar Perfil</a>.
                        </v-alert>
                      </div>
                  </v-card-text>
                  <v-card-actions class="justify-space-around">
                    <v-btn text @click="close">
                      Fechar
                    </v-btn>

                    <v-btn
                      color="primary"
                      @click="e1 = 2"
                    >
                      Continuar
                    </v-btn>
                  </v-card-actions>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card>
                  <div>
                    <v-alert
                      border="top"
                      colored-border
                      type="info"
                      elevation="2"
                      v-if="verificado != 3"
                    >
                      Fizemos uma pequena cobrança com um valor entre R$ 0,01 a
                      R$ 1,00, informe-nos de quanto foi o valor cobrado.
                    </v-alert>
                    <v-alert v-if="loading" text type="warning">
                      Aguarde a verificação!
                    </v-alert>
                    <v-alert v-if="verificado == 3 && !loading" text type="success">
                      Cartão verificado!
                    </v-alert>
                  </div>
                  <v-layout justify-center class="ml-3 mr-3">
                    <v-text-field
                      v-model="data_validar.value"
                      label="Digite o valor que foi cobrado em seu cartão"
                      v-mask="'#,##'"
                      required
                      outlined
                      prefix="R$:"
                    ></v-text-field>
                  </v-layout>
                  <v-card-actions class="justify-space-around">
                    <v-flex :xs="verificado != 3 ? 12 : 6">
                      <v-btn text block @click="close">
                        Fechar
                      </v-btn>
                    </v-flex>
                    <v-divider vertical v-if="verificado != 3"></v-divider>
                    <v-flex xs6 v-if="verificado != 3">
                      <v-btn
                        color="primary"
                        block
                        text
                        @click="validar"
                        :loading="loading"
                      >
                        Confirmar
                      </v-btn>
                    </v-flex>
                  </v-card-actions>
                  </v-card>
                </v-stepper-content>

              </v-stepper-items>
            </v-stepper>
          </template>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" v-if="dialogDelete" max-width="500px">
          <v-card style="border-radius: 1em;">
            <v-card-title>
              <h3 style="text-align: center; width: 100%" class="headliner error--text">Você quer excluir esse cartão?</h3>
            </v-card-title>
            <v-card-actions class="justify-space-around mt-4">
              <v-btn text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="error" text @click="deleteItemConfirm">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-alert v-if="error" type="error" class="mt-4 ml-1 mr-1 mb-1 pa-1">
        <h4>{{error}}</h4>
      </v-alert>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu
          left
          offset-y
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item ripple @click="editItem(item)" :disabled="item.verificado === 3">
            <v-list-item-action>
              <v-icon :color="item.verificado === 3 ? 'success' : ''">done_all</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              Verificar Cartão
            </v-list-item-content>
          </v-list-item>
          <v-list-item ripple @click="deleteItem(item)" :loading="loading">
            <v-list-item-action>
              <v-icon color="error">mdi-delete</v-icon>
            </v-list-item-action>
            <v-list-item-content class="error--text">
              Deletar
            </v-list-item-content>
          </v-list-item>
        </v-list>
        </v-menu>
    </template>
    <template v-slot:no-data>
      Nenhum cartão cadastrado no momento
    </template>
  </v-data-table>
  <v-dialog v-model="dialogAtivar" v-if="dialogAtivar" width="500">
    <dialog-ativar-cartao @carregarCartoes="listarcartoes" @fechar="dialogAtivar = false" :cartao="cartaoSelecionado"/>
  </v-dialog>
  </v-layout>
</template>

<script>

import DialogAtivarCartao from '@/components/dialogs/AtivarCartao'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: { DialogAtivarCartao, AvisoBloqueio },
  data: () => ({
    hideCheckA: false,
    hideCheckB: false,
    dialog: false,
    dialogDelete: false,
    dialogCard: false,
    dialogAtivar: false,
    cartaoSelecionado: null,
    stepCard: 1,
    error: null,
    tipo_pessoa: '1',
    headers: [
      {
        text: 'Descrição',
        sortable: false,
        align: 'start',
        value: 'descricao'
      },
      {
        text: 'Número do cartão (Inicio) ',
        align: 'start',
        sortable: false,
        value: 'inicio'
      },
      { text: 'Número do cartão (Final)', value: 'final' },
      { text: 'Tipo de pagamento', value: 'credito' },
      { text: 'Validado', value: 'verificado' },
      { value: 'isActive', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    desserts: [],
    editedIndex: -1,
    requiredRules: [v => !!v || 'Esse campo é obrigatório'],
    editedItem: {
      id_cartao: 0,
      name: '',
      inicio: 0,
      final: 0,
      tipo_pagamento: true,
      verificado: 0
    },
    defaultItem: {
      name: '',
      inicio: 0,
      final: 0,
      tipo_pagamento: 0,
      verificado: 0
    },
    e1: 1,
    valid: false,
    data_validar: {
      value: null,
      option: 'verify'
    },
    flaginitValidar: false,
    cartao: {
      titular: null,
      numero_cartao: null,
      validade_cartao: null,
      cvv_cartao: null,
      bandeira_card: null,
      credito: true,
      descricao: null
    },
    endereco_cartao: {
      cep: null,
      rua: null,
      numero: null,
      complemento: null,
      cidade: null,
      estado: null,
      pais: null,
      bairro: null
    },
    infoPgto: {
      url_pagamento: null,
      id_compra: null,
      user_agent: null,
      nome: null,
      email: null,
      cpf: null,
      data_nascimento: null
    },
    validade: null,
    id_cartao: null,
    verificado: 2
  }),

  computed: {
    mesmoEndereco () {
      if (this.empresa.endereco == null) return false
      else if (this.pessoa.endereco && this.empresa.endereco.cep === this.pessoa.endereco.cep && this.empresa.endereco.numero === this.pessoa.endereco.numero) return true
      else return false
    },
    formTitle () {
      return this.editedIndex === -1 ? 'Novo cartão' : 'Validar Cartão'
    },
    empresa () {
      return this.$store.getters.empresa
    },
    cartoes () {
      return this.$store.getters.cartoes
    },
    cartao_local () {
      const cartao = this.$store.getters.cartao
      if (cartao && typeof cartao === 'object') {
        return cartao
      } else if (cartao && typeof cartao === 'string') {
        const idCartao = Number(cartao.split('-')[0])
        return {
          id_cartao: idCartao,
          hash_code: cartao.split('-')[1]
        }
      }
      return this.$store.getters.cartao
    },
    card_hash () {
      const result = {
        titular: {
          nome: this.cartao.titular
        },
        endereco_cobranca: this.endereco_cartao,
        numero_cartao: this.cartao.numero_cartao,
        cvv: this.cartao.cvv_cartao,
        mes_expiracao: this.validade ? this.validade[0] : null,
        ano_expiracao: this.validade ? this.validade[1] : null,
        debito: false,
        credito: this.cartao.credito,
        verificado: this.verificado,
        descricao: this.cartao.descricao
      }
      return result
    },
    infoCompraFinalizar () {
      return {
        url: this.infoPgto.url_pagamento,
        id_compra: this.infoPgto.id_compra,
        id_cartao: null,
        cvv: this.cartao.cvv_cartao,
        cartao: '',
        user_agent: this.infoPgto.user_agent
      }
    },
    pessoa () {
      return this.$store.getters.pessoa
    },
    loading () {
      return this.$store.getters.loading
    }
  },
  watch: {
    dialog (val) {
      if (val && this.pessoa && this.pessoa.verificado) {
        this.e1 = 2
      } else this.e1 = 1
      val || this.close()
    },
    dialogCard (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    empresa (val, oldval) {
      if (val !== null && val !== undefined && val !== oldval) {
        this.carregarDados()
      }
    },
    pessoa (val, oldval) {
      if (val !== null && val !== undefined) {
        this.listarcartoes()
      }
    }
    // desserts (newName) {
    //   localStorage.desserts = newName
    // }
  },

  beforeMount () {
    if (this.empresa && this.empresa.administrador) {
      this.carregarDados()
    }
    if (this.pessoa !== null && this.pessoa !== undefined) {
      this.listarcartoes()
    }
    // this.tornar_cliente()
  },

  mounted () {
    if (this.empresa && this.empresa.administrador) {
      this.carregarDados()
    }
    if (this.pessoa !== null && this.pessoa !== undefined) {
      this.listarcartoes()
    }
    // if (localStorage.desserts) {
    //   this.desserts = localStorage.desserts
    // }
  },

  methods: {
    initialize () {
      this.listarcartoes()
    },
    selecionarCartao (cartao) {
      this.cartaoSelecionado = cartao
      this.dialogAtivar = true
    },
    searchCep () {
      if (this.endereco_cartao.cep.length < 8) return
      fetch(`https://viacep.com.br/ws/${this.endereco_cartao.cep}/json/`, {
        method: 'get',
        mode: 'cors',
        headers: {
          Accept: 'application/json'
        }
      })
        .then((res) => res.json())
        .then((json) => {
          this.endereco_cartao.logradouro = json.logradouro
          this.endereco_cartao.bairro = json.bairro
          this.endereco_cartao.cidade = json.localidade
          this.endereco_cartao.estado = json.uf
        })
    },
    editItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    selecionarEndereco (endereco) {
      this.endereco_cartao = endereco
      this.endereco_cartao.num = endereco.numero
      this.endereco_cartao.rua = endereco.logradouro
      this.stepCard = 3
    },
    deleteItem (item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.deleteCartao()
    },
    isActiveCard (cartao) {
      if (!cartao) return false
      if (cartao !== undefined && cartao !== null && this.cartao_local && cartao.id_cartao === this.cartao_local.id_cartao) return true
      return false
    },
    close () {
      this.listarcartoes()
      this.dialog = false
      this.dialogCard = false
      this.e1 = 1
      this.error = null
      this.valid = false
      this.stepCard = 1
      this.verificado = 0
      this.endereco_cartao = {
        cep: null,
        rua: null,
        numero: null,
        complemento: null,
        cidade: null,
        estado: null,
        pais: null,
        bairro: null
      }
      this.$emit('fechar')
    },

    closeDelete () {
      this.dialogDelete = false
      this.listarcartoes()
    },

    closeCartao () {
      this.$refs.formCreditCard.reset()
      this.dialogCard = false
    },

    validar () {
      this.validarCartao()
      this.el = 4
    },
    tornar_cliente () {
      this.$store.dispatch('tornarCliente')
    },
    validarCartao () {
      this.data_validar.value = parseFloat(this.data_validar.value.replace(',', '.'))
      if (this.editedIndex === -1) {
        var index = this.desserts.length
        this.editedIndex = index - 1
      }
      this.id_cartao = this.editedItem.id_cartao
      this.$store.dispatch('confirmarCartao', { id: this.id_cartao, data: this.data_validar })
        .then(() => {
          this.verificado = 3
        })
        .catch(error => {
          this.error = ' ' + error
        })
      this.editedIndex = -1
    },
    deleteCartao () {
      this.id_cartao = this.editedItem.id_cartao
      this.$store.dispatch('deleteCartao', this.id_cartao)
        .then(() => {
          if (this.isActiveCard(this.editedItem)) {
            this.$store.dispatch('apagarDadosCartao')
          }
          this.desserts.splice(this.editedIndex, 1)
          this.closeDelete()
          this.close()
        })
        .catch(error => {
          this.$emit('error', error)
          this.close()
        })
    },
    checkCartao () {
      if (this.cartao.credito === 'credito' || this.cartao.credito === true) {
        this.cartao.credito = true
      } else {
        this.cartao.credito = false
      }
    },
    async hashcartao () {
      if (this.$refs.formCreditCard.validate()) {
        await this.checkCartao()
        this.validade = this.cartao.validade_cartao.split('/')
        this.cartao.numero_cartao = this.cartao.numero_cartao.replace(/ /g, '')
        const data = {
          cartao: this.card_hash
        }
        if (this.tipo_pessoa === '2') data.cnpj_empresa = this.empresa.cnpj
        const cartao = await this.$store.dispatch('cadastarCartao', data)
        this.infoCompraFinalizar.cartao = cartao.hash_code
        this.infoCompraFinalizar.id_cartao = cartao.id_cartao
        this.editedItem.id_cartao = cartao.id_cartao
        await this.listarcartoes()
        await this.iniciarVerificacao()
        this.closeCartao()
      } else {
        this.$store.dispatch('setError', 'Você precisa preencher todos os campos do formulário.')
      }
    },
    carregarDados () {
      this.infoPgto.user_agent = navigator.userAgent
    },
    iniciarVerificacao () {
      this.id_cartao = this.infoCompraFinalizar.id_cartao
      this.$store
        .dispatch('iniciarVerificacao', { id: this.id_cartao, hash: this.infoCompraFinalizar.cartao, cartao: this.card_hash })
        .then(json => {
          this.e1 = 3
        })
        .catch(error => {
          // this.$emit('error', 'Error na verificação tente novamente' + error)
          this.$emit('error', error)
          this.close()
        })
    },
    // listarcartoes () {
    //   this.desserts = this.cartoes
    // }
    listarcartoes () {
      this.$store.dispatch('listarCartoes', this.pessoa.id_pessoa).then(cartoes => {
        this.desserts = cartoes
      })
        .catch(error => {
          this.$emit('error', error)
          this.close()
        })
    }
  }
}
</script>
