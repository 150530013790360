var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.empresa && (!_vm.empresa.ativo || _vm.empresa.tipo_empresa !== 2))?_c('aviso-bloqueio'):_c('v-layout',{staticClass:"pt-5",attrs:{"align-space-around":"","justify-start":"","fill-height":"","column":""}},[_c('v-toolbar',{staticClass:"elevation-0 secondary--text",staticStyle:{"background":"transparent"},attrs:{"color":"transparent"}},[_c('v-toolbar-title',[_vm._v("Meus Leilões")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","color":"info"},on:{"click":function($event){_vm.showFilter = !_vm.showFilter}}},on),[_c('v-icon',[_vm._v(_vm._s(!_vm.showFilter ? 'filter_list' : 'filter_list_off'))])],1)]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilter ? 'Mostrar filtros' : 'Recolher filtros'))])])],1),_c('v-slide-x-transition',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilter),expression:"showFilter"}],staticClass:"ml-4 mr-4"},[_c('v-card-text',[_c('v-layout',{attrs:{"wrap":"","justify-space-between":""}},[_c('v-flex',{attrs:{"xs10":"","md11":""}},[_c('v-layout',{attrs:{"wrap":"","justify-space-around":""}},[_c('v-flex',{attrs:{"xs11":"","sm5":"","md3":"","lg2":"","xl2":""}},[_c('v-text-field',{attrs:{"label":"Código do veículo"},model:{value:(_vm.id_veiculo),callback:function ($$v) {_vm.id_veiculo=$$v},expression:"id_veiculo"}})],1),_c('v-flex',{attrs:{"xs11":"","sm5":"","md4":"","lg3":"","xl2":""}},[_c('v-select',{attrs:{"label":"Status","items":[
                      { text: 'Aberto', value: 1 },
                      { text: 'Encerrado', value: 2 },
                      { text: 'Cancelado', value: 3 },
                      { text: 'Em andamento', value: 4 },
                      { text: 'Concluído', value: 5 }
                    ],"item-text":"text","item-value":"value","clearable":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-flex',{attrs:{"xs11":"","sm5":"","md4":"","lg3":"","xl2":""}},[_c('v-select',{attrs:{"label":"Responsável","items":[
                      { text: 'Você', value: 1 },
                      { text: 'Cliente', value: 2 },
                      { text: 'Empresa', value: 3 }
                    ],"item-text":"text","item-value":"value","clearable":""},model:{value:(_vm.responsavel),callback:function ($$v) {_vm.responsavel=$$v},expression:"responsavel"}})],1),_c('v-flex',{attrs:{"xs11":"","sm5":"","md4":"","lg3":"","xl2":""}},[_c('v-select',{attrs:{"label":(_vm.selectOpcoes + " de início"),"items":_vm.options,"item-text":"title","item-value":"value"},model:{value:(_vm.selectOpcoes),callback:function ($$v) {_vm.selectOpcoes=$$v},expression:"selectOpcoes"}})],1),_c('v-flex',{attrs:{"xs11":"","sm5":"","md4":"","lg3":"","xl2":""}},[_c('v-menu',{ref:"menuDataInicial",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [(_vm.opcoes.dia)?_c('v-combobox',_vm._g({attrs:{"label":'Data de Início',"persistent-hint":"","chips":"","small-chips":"","readonly":"","flat":"","prepend-icon":"event","required":"","clearable":""},on:{"blur":function($event){_vm.date = _vm.parseDate(_vm.opcoes.data_relatorio)}},model:{value:(_vm.opcoes.data_relatorio),callback:function ($$v) {_vm.$set(_vm.opcoes, "data_relatorio", $$v)},expression:"opcoes.data_relatorio"}},on)):_c('v-combobox',_vm._g({attrs:{"label":'Mês de início',"persistent-hint":"","chips":"","small-chips":"","readonly":"","flat":"","prepend-icon":"event","required":"","clearable":""},on:{"blur":function($event){_vm.month = _vm.parseDate(_vm.opcoes.mes_relatorio)}},model:{value:(_vm.opcoes.mes_relatorio),callback:function ($$v) {_vm.$set(_vm.opcoes, "mes_relatorio", $$v)},expression:"opcoes.mes_relatorio"}},on))]}}]),model:{value:(_vm.menuDataInicial),callback:function ($$v) {_vm.menuDataInicial=$$v},expression:"menuDataInicial"}},[_c('v-date-picker',{directives:[{name:"show",rawName:"v-show",value:(_vm.opcoes.dia),expression:"opcoes.dia"}],attrs:{"type":"date","no-title":"","locale":"pt-br"},on:{"input":_vm.dataSelecionada},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-date-picker',{directives:[{name:"show",rawName:"v-show",value:(_vm.opcoes.mes),expression:"opcoes.mes"}],attrs:{"type":"month","no-title":"","locale":"pt-br"},on:{"input":_vm.dataSelecionada},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})],1)],1)],1)],1),_c('v-flex',{attrs:{"xs2":"","md1":"","align-self-center":""}},[_c('v-btn',{attrs:{"fab":"","small":"","color":"info"},on:{"click":function($event){return _vm.pegarLeiloes(true)}}},[_c('v-icon',[_vm._v("search")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","headers":[
        {
          sortable: false
        },
        {
          text: 'Veículo',
          value: 'veiculo_leiloado',
          sortable: false
        },
        {
          text: 'Responsável',
          value: 'empresa',
          sortable: false
        },
        {
          text: 'Data de início',
          align: 'left',
          sortable: false,
          value: 'data_inicio'
        },
        {
          text: 'Data de encerramento',
          align: 'left',
          sortable: false,
          value: 'data_fim'
        },
        {
          text: 'Quantidade de lances',
          align: 'center',
          sortable: false,
          value: 'qtd_lances'
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'left'
        },
        {
          align: 'right',
          sortable: false
        }
      ],"items":_vm.leiloes,"options":_vm.pagination,"no-data-text":"Você ainda não possui leilões cadastrados"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',[_c('td',{staticClass:"text-xs-left"},[_c('v-icon',[_vm._v("gavel")])],1),_c('td',{staticClass:"text-xs-left"},[(props.item.veiculo_leiloado.modelo && props.item.veiculo_leiloado.marca)?[_vm._v(" "+_vm._s(props.item.veiculo_leiloado.modelo.split(' ')[0] || '')+"/"+_vm._s(props.item.veiculo_leiloado.marca)+" ")]:_vm._e()],2),_c('td',{staticClass:"text-xs-left"},[(props.item.empresa && _vm.empresa)?[_vm._v(" "+_vm._s(props.item.empresa.cnpj == _vm.empresa.cnpj ? 'Você' : _vm.substituirPorAsteriscos(props.item.empresa.nome_fantasia, props.item))+" ")]:_vm._e(),(props.item.cliente)?[_vm._v(" "+_vm._s(_vm.substituirPorAsteriscos(props.item.cliente.pessoa.nome_completo, props.item))+" ")]:_vm._e()],2),_c('td',[_vm._v(" "+_vm._s(_vm._f("formatDate")(props.item.data_inicio,'DD/MM/YYYY'))+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(props.item.data_fim,'DD/MM/YYYY')))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(props.item.qtd_lances)+" ")]),_c('td',{staticClass:"text-xs-left"},[(props.item.status==3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"red","dark":""}},on),[_vm._v("cancel")])]}}],null,true)},[_c('span',[_vm._v("Cancelado")])]):(props.item.status==5)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"teal","dark":""}},on),[_vm._v("check_circle")])]}}],null,true)},[_c('span',[_vm._v("Concluído")])]):(props.item.status==2 || _vm.verificarEncerramento(props.item.data_fim))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("timer_off")])]}}],null,true)},[_c('span',[_vm._v("Enderrado")])]):(props.item.status==1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v("timer")])]}}],null,true)},[_c('span',[_vm._v("Em Aberto")])]):(props.item.status==4)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","dark":""}},on),[_vm._v("change_circle")])]}}],null,true)},[_c('span',[_vm._v("Em andamento")])]):_vm._e()],1),_c('td',{staticClass:"text-xs-right"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.leilaoSelecionado = props.item,
                    _vm.dialogVisualizarLeilao = true}}},on),[_c('v-icon',[_vm._v("remove_red_eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Detalhes do leilão")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":"","color":"info","disabled":props.item.status === 5 || props.item.status === 3 || props.item.status === 2 || (props.item.empresa && _vm.empresa && props.item.empresa.cnpj !== _vm.empresa.cnpj)},on:{"click":function($event){_vm.leilaoSelecionado = props.item,
                    _vm.dialogEditarLeilao = true}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar leilão")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-1",attrs:{"icon":"","color":"red","disabled":props.item.status === 3 || props.item.status === 5 || (props.item.empresa && _vm.empresa && props.item.empresa.cnpj !== _vm.empresa.cnpj)},on:{"click":function($event){_vm.leilaoSelecionado = props.item,
                    _vm.dialogExcluirLeilao = true}}},on),[_c('v-icon',[_vm._v("cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Excluir leilão")])])],1)])]}}])}),_c('div',{staticClass:"text-xs-center"},[_c('v-pagination',{attrs:{"length":_vm.pages,"circle":""},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),(_vm.dialogVisualizarLeilao)?_c('v-dialog',{attrs:{"width":"55em","scrollable":""},model:{value:(_vm.dialogVisualizarLeilao),callback:function ($$v) {_vm.dialogVisualizarLeilao=$$v},expression:"dialogVisualizarLeilao"}},[_c('visualizar-leilao',{attrs:{"leilaoSelecionado":_vm.leilaoSelecionado},on:{"fechar":function($event){_vm.dialogVisualizarLeilao = false,
        _vm.leilaoSelecionado = undefined},"carregar":function($event){return _vm.pegarLeiloes(false)}}})],1):_vm._e(),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogExcluirLeilao),callback:function ($$v) {_vm.dialogExcluirLeilao=$$v},expression:"dialogExcluirLeilao"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center error--text",staticStyle:{"font-size":"1.3em"}},[_vm._v(" Você deseja excluir esse leilão? ")]),_c('v-card-actions',{staticClass:"justify-space-around"},[_c('v-btn',{staticClass:"mb-3",attrs:{"rounded":""},on:{"click":function($event){_vm.dialogExcluirLeilao = false}}},[_vm._v("Não")]),_c('v-btn',{staticClass:"mb-3",attrs:{"rounded":"","color":"error"},on:{"click":_vm.excluirLeilao}},[_vm._v("Sim")])],1)],1)],1),(_vm.dialogEditarLeilao)?_c('v-dialog',{attrs:{"width":"50em"},model:{value:(_vm.dialogEditarLeilao),callback:function ($$v) {_vm.dialogEditarLeilao=$$v},expression:"dialogEditarLeilao"}},[_c('dialog-editar-leilao',{attrs:{"leilaoSelecionado":_vm.leilaoSelecionado},on:{"fechar":function($event){_vm.dialogEditarLeilao = false, _vm.leilaoSelecionado = undefined},"carregar":function($event){return _vm.pegarLeiloes(false)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }